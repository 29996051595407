.dropdown-button-container {
    position: relative;
    /*margin: 1rem 0;*/
    width: 100%;
    outline: none;
    border: 2px solid #fafafa;
    background: none;
    padding: 0.6rem 1.2rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.95rem;
    border-radius: 25px;
    transition: 0.3s;
}

.dropdown-button {
    width: 100%;
    background-color: #22553f;
    color: white;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    text-align: left;
}



/*!* Dropdown button on hover & focus *!*/
/*.dropbtn:hover, .dropbtn:focus {*/
/*    background-color: #2980B9;*/
/*}*/

/* The container <div> - needed to position the dropdown content */
.dropdown {
    width: 100%;
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #22553f;
    min-width: 160px;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 10000;
    max-height: 200px;
    overflow-y: auto;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: white;
    /*padding: 12px 16px;*/
    text-decoration: none;
    /*display: block;*/
}

.dropdown-content i {
    color: white;
    font-size: 20px;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}

.dropdown-item {
    display: flex;
    justify-content: left;
    padding: 5px;
    padding-left: 15px;
    width: 100%;
    color: white;
}

.dropdown-item:hover {
    background-color: deepskyblue;
    cursor: pointer;
}

.country-list {
    background-color: #22553f !important;
    z-index: 99999 !important;
}

::-webkit-scrollbar {
    height: 4px;              /* height of horizontal scrollbar ← You're missing this */
    width: 4px;               /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
}