
.social-media {
    padding: 2rem 0 0 0;
}

.social-media p {
    color: #333;
}

.social-icons {
    display: flex;
    margin-top: 0.5rem;
}

.social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(45deg, #22553f, #149279);
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: 0.3s;
}

.social-icons a:hover {
    transform: scale(1.05);
}